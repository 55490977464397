import React from 'react';
import './style.scss';
import MenuIcon from '@mui/icons-material/Menu';
import NavbarIcon from '../navbarIcon/navbarIcon';
import NavbarT from './navbarT';
const Navbar = () => {
    return(
        <div className="navbar">
        
            <div ><NavbarIcon />
            </div>
            
       
        </div>
    )
}

export default Navbar;
