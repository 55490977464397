/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 cell.glb 
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Cell(props) {
  const { nodes, materials } = useGLTF('./models/cell.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes['2'].geometry} material={materials['2']} position={[-0.599, 0, 0]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
      <mesh geometry={nodes.Sphere.geometry} material={materials['Material.001']} position={[-0.455, -0.03, 0.134]} scale={0.05} />
      <mesh geometry={nodes.Sphere001.geometry} material={materials['Material.002']} position={[-0.433, -0.133, 0.01]} scale={0.05} />
      <mesh geometry={nodes.Sphere002.geometry} material={materials['Material.003']} position={[-0.496, -0.133, -0.078]} scale={0.05} />
      <mesh geometry={nodes.Sphere003.geometry} material={materials['Material.004']} position={[-0.468, -0.207, -0.025]} scale={0.05} />
      <mesh geometry={nodes.Sphere004.geometry} material={materials['Material.005']} position={[-0.469, -0.256, 0.192]} scale={0.05} />
      <mesh geometry={nodes.Sphere005.geometry} material={materials['Material.006']} position={[-0.449, 0.208, 0.201]} scale={0.05} />
      <mesh geometry={nodes.Sphere006.geometry} material={materials['Material.007']} position={[-0.449, 0.125, 0.161]} scale={0.05} />
      <mesh geometry={nodes.Sphere007.geometry} material={materials['Material.008']} position={[-0.445, 0.151, 0.241]} scale={0.05} />
    </group>
  )
}

useGLTF.preload('./models/cell.glb')
