import Navbar from "../components/Navbar/navbar";
import Example from "../components/SceneTheater/example";
const ScenePage = () =>{
    return(
        <>
            <Example />
        </>
    )
}


export default ScenePage;